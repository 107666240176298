<i18n lang="json">
{
  "ru": {
    "check_in_out_0": "ранний заезд <span>(до {check_in})</span> и поздний отъезд <span>(после {check_out})</span> не предусмотрены",
    "check_in_out_2": "ранний заезд <span>(до {check_in})</span> и поздний отъезд <span>(после {check_out})</span> согласуйте дополнительно",
    "check_in_early_0": "ранний заезд <span>(до {check_in})</span> не предусмотрен",
    "check_in_early_2": "ранний заезд <span>(до {check_in})</span> согласуйте дополнительно",
    "check_out_late_0": "поздний отъезд <span>(после {check_out})</span> не предусмотрен",
    "check_out_late_2": "поздний отъезд <span>(после {check_out})</span> согласуйте дополнительно",
    "documents_0": "владелец не предоставляет отчетные документы о проживании",
    "documents_1": "владелец предоставляет отчетные документы о проживании",
    "documents_2": "владелец предоставляет отчетные документы о проживании по согласованию",
    "accommodationRules": {
      "smoking":{
        "0": "курение запрещено",
        "1": "курение разрешено",
        "3": "курение разрешено в специально отведенных местах"
      },
      "pets":{
        "0": "нельзя с питомцами",
        "1": "можно с питомцами",
        "2": "можно с питомцами по согласованию с хозяином жилья"
      },
      "party":{
        "0": "без вечеринок и мероприятий",
        "1": "можно проводить вечеринки и мероприятия",
        "2": "вечеринки и мероприятия по согласованию с хозяином жилья"
      },
      "isolation":{
        "1": "самоизоляция разрешена"
      }
    },
    "is_children": {
      "0": "нельзя с детьми",
      "1": {
        "0": "можно с детьми любого возраста",
        "1": "можно с детьми от 1 года и старше",
        "2": "можно с детьми от {age} лет и старше"
      },
      "2": "можно с детьми по согласованию с хозяином жилья"
    },
    "is_children_free_babies": "нельзя с детьми до {age} лет, но можно с детьми 1 года и младше",
    "free_babies": "дети 1 года и младше размещаются бесплатно, но без дополнительного спального места",
    "ages": "0|c {n} года | {n} лет| {n} лет",
    "titleBlock": "Убедитесь, что жильё вам подходит",
    "additionally": "Дополнительно",
    "deposit_text1": "страховой депозит при заезде",
    "deposit_text2": "(возращается при выселении)",
    "free_cancellation": "Бесплатная отмена до",
    "free_cancellation_text": "При отмене не позднее 5 дней до заезда вы получите полный возврат предоплаты.",
    "searchJD": "искать ж/д билеты в отдельной вкладке"
  },
  "en": {
    "check_in_out_0": "early check-in <span> (before {check_in}) </span> and late check-out <span> (after {check_out}) </span> are not available",
    "check_in_out_2": "early check-in <span> (before {check_in}) </span> and late check-out <span> (after {check_out}) </span> please agree additionally",
    "check_in_early_0": "early check-in <span> (before {check_in}) </span> is not provided",
    "check_in_early_2": "early check-in <span> (before {check_in}) </span> please agree additionally",
    "check_out_late_0": "late check-out <span> (after {check_out}) </span> is not available",
    "check_out_late_2": "late check-out <span> (after {check_out}) </span> please agree additionally",
    "documents_0": "the owner does not provide accounting documents about the residence",
    "documents_1": "the owner provides accounting documents about the residence",
    "documents_2": "the owner provides accounting documents on residence as agreed",
    "accommodationRules": {
      "smoking":{
        "0": "no smoking",
        "1": "smoking allowed",
        "3": "smoking is allowed in designated areas"
      },
      "pets":{
        "0": "pets are not allowed",
        "1": "pets allowed",
        "2": "it is possible with pets in agreement with the landlord"
      },
      "party":{
        "0": "no parties and events",
        "1": "parties allowed",
        "2": "parties and events as agreed with the host"
      },
      "isolation":{
        "1": "self-isolation allowed"
      }
    },
    "is_children": {
      "0": "not with children",
      "1": {
        "0": "possible with children of any age",
        "1": "possible with children from 1 year old and older",
        "2": "it is possible with children from {age} years old and older"
      },
      "2": "it is possible with children in agreement with the landlord"
    },
    "is_children_free_babies": "not allowed with children under {age} years old, but allowed with children 1 year and younger",
    "free_babies": "children 1 year and younger are accommodated free of charge, but without an extra bed",
    "ages": "0| c {n} years | {n} years | {n} years",
    "titleBlock": "Make sure your home is right for you",
    "additionally": "Additional",
    "deposit_text1": "security deposit upon arrival",
    "deposit_text2": "(returned upon checkout)",
    "free_cancellation": "Free Cancellation Until",
    "free_cancellation_text": "If canceled within 5 days prior to arrival, you will receive a full refund of the prepayment.",
    "searchJD": "search for railway tickets in a separate tab"
  }
}
</i18n>
<template>
  <div class="rules-booking" :class="{ 'is-desktop': isDesktop }">
    <div v-if="title && isDesktop" class="rules-booking--title-custom tmp-font--average_bold">{{ title }}</div>
    <div class="rules-booking--title" v-else>
      {{ t("titleBlock") }}
    </div>
    <div class="rules-booking--block">
      <div class="rules-booking--body">
        <div class="rules-booking__grid">
          <div class="rule" v-if="children">
            {{ children }}
            <template v-if="isFreeBabies">
              <span class="hint-popup" :data-hint="t('free_babies')">
                <span class="icon-app-question"></span>
              </span>
            </template>
          </div>
          <div class="rule" v-for="(el, key) in accommodationRulesTemplate" :key="key" >
            {{el.title}}
          </div>
          <div class="rule" v-if="documents">
            {{ t(documents) }}
          </div>
        </div>
        <div class="rules-booking__additional">
          <div class="rule-head" v-if="!isDesktop">
            {{ t("additionally") }}
          </div>
          <div class="rule" v-html="check_in_out_text"></div>
          <div class="rule" v-if="deposit.value">
            {{ formatNumber(deposit.value) }} {{ t("deposit_text1") }} <span>{{ t("deposit_text2") }}</span>
          </div>
        </div>
      </div>
      <div class="free-cancellation" v-if="isFreeCancellation">
        <div class="free-cancellation--title">
          <span><span class="zero"></span>₽</span>
          {{ t("free_cancellation") }} {{ dateFreeCancellation }}
        </div>
        <div class="free-cancellation--body">
          {{ t("free_cancellation_text") }}
        </div>
      </div>
      <div class="grandtrain-wrap" v-if="viewPort === 'mobile' && isGrand">
        <b-form-checkbox v-model="isCheck">{{ t('searchJD') }}</b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex"
import { useI18n } from 'vue-i18n';

export default {
  name: "RulesBookingBody",
  props: {
    isDesktop: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    isGrandtrain: {
      type: Boolean,
      default: false
    },
    isCheckGrandtrain: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      check_in_early: 0,
      check_out_late: 0,
      check_in: "",
      check_out: "",
      isCheck: false
    }
  },
  mounted() {
    this.check_in_out();
    this.isCheck = this.isCheckGrandtrain;
  },
  watch: {
    getObjectData() {
      this.check_in_out()
    },
    isCheck (val) {
      this.$emit('update:modelValue', val);
    }
  },
  methods: {
    formatNumber(num) {
      const originalCurrency = this.getObjectData?.original_currency?.code;
      return new Intl.NumberFormat("ru-Ru", {
        style: "currency",
        currency: originalCurrency ? originalCurrency : "RUB",
        minimumFractionDigits: 0,
      }).format(num);
    },
    check_in_out() {
      if (this.getObjectData?.properties?.check_in_out_early_rules) {
        this.getObjectData.properties.check_in_out_early_rules.properties.forEach(item => {
          if (item.name === "check_in_early") {
            this.check_in_early = item.value
          } else if (item.name === "check_out_late") {
            this.check_out_late = item.value
          }
        })
      }
      if (this.getObjectData?.properties?.check_in_out_rules) {
        this.getObjectData.properties.check_in_out_rules.properties.forEach(item => {
          if (item.name === "check_in") {
            this.check_in = item.value
          } else if (item.name === "check_out") {
            this.check_out = item.value
          }
        })
      }
    },
  },
  computed:{
    ...mapState("search", ["checkIn"]),
    ...mapGetters("detail", ["getObjectData"]),
    ...mapGetters("search", ["occupied"]),
    ...mapState("user", ["viewPort"]),
    isGrand () {
      return this.isGrandtrain;
    },
    isFc() {
      let item = false;
      if (this.getObjectData?.properties?.hidden_properties) {
        item = this.getObjectData.properties.hidden_properties.properties.find(
          (item) => item.name == "fc"
      )
        item = item ? item.value : false
      }
      return item
    },
    isFreeCancellation() {
      if (this.isFc) {
        if (this.occupied && this.checkIn) {
          let сurrentTime = new Date(new Date().toISOString().slice(0, 10) + 'T00:00:00').getTime()
          let amountDays = Math.round((this.checkIn.getTime() - сurrentTime) / 86400000)

          if (amountDays < 5) {
            return false
          }
        }
        return true
      } else {
        return false
      }
    },
    dateFreeCancellation() {
      if (this.checkIn) {
        let lastDay = new Date(this.checkIn.getTime() - (86400000*4))
        return lastDay.toLocaleString("ru-RU", {
          day: "numeric", month: "long", year: "numeric"
        }).replace(' г.', '')
      } else {
        return ''
      }
    },
    filteredAccommodationRules(){
      const requiredProperties = ['smoking', 'pets', 'party', 'is_children', 'children_age'];
      if (this.getObjectData?.properties?.accommodation_rules?.properties) {
        return this.getObjectData?.properties?.accommodation_rules?.properties.filter(
          (item) => {
            return requiredProperties.includes(item.name);
          }
        );
      }
      return null;
    },
    accommodationRulesTemplate() {
      if (this.filteredAccommodationRules) {
        return this.filteredAccommodationRules
          .filter(item => item.name !== "is_children" && item.name !== "children_age")
          .map(item => {
              return {
                title: this.t(`accommodationRules.${item.name}.${item.value}`)
              }
          })
      } else {
        return []
      }
    },
    deposit() {
      if (this.getObjectData?.properties?.additional_payment?.properties) {
        let deposit = this.getObjectData?.properties?.additional_payment?.properties.find(item => item.name === "deposit")
        return deposit ? deposit : false
      } else {
        return false
      }
    },
    documents() {
      if (this.getObjectData?.properties?.documents_rules?.properties) {
        let documents = this.getObjectData?.properties?.documents_rules?.properties.find(item => item.name === "documents")
        return documents ? this.t(`documents_${documents.value}`) : false
      } else {
        return false
      }
    },
    children() {
      const properties = this.filteredAccommodationRules;
      if (properties) {
        const is_children = properties.find(item => item.name === 'is_children');
        const children_age = properties.find(item => item.name === 'children_age');
        if (is_children?.value === 1) {
          if (children_age?.value <= 2 && this.isFreeBabies) {
            return this.t('is_children.1.0');
          } else if (children_age?.value > 2 && this.isFreeBabies) {
            return this.t('is_children_free_babies', {
              age: children_age.value,
            });
          } else if (children_age?.value <= 1) {
            return this.t(
              `is_children.${is_children.value}.${children_age.value}`
            );
          } else {
            return this.t(`is_children.1.2`, {
              age: children_age.value,
            });
          }
        } else if (is_children?.value === 0 || is_children?.value === 2) {
          return this.t(`is_children.${is_children.value}`);
        } else {
          return false;
        };
      } else {
        return false;
      };
    },
    isFreeBabies() {
      const properties =
        this.getObjectData?.properties?.accommodation_rules?.properties;
      const isFreeBabies = properties?.find(item => item.name === 'free_babies')?.value
      return isFreeBabies === "false" ? false : Boolean(isFreeBabies);
    },
    check_in_out_text() {
      if (this.check_in_early === 0 && this.check_out_late === 0) {
        return this.t("check_in_out_0", {"check_in": this.check_in, "check_out": this.check_out})
      } else if (this.check_in_early === 2 && this.check_out_late === 2) {
        return this.t("check_in_out_2", {"check_in": this.check_in, "check_out": this.check_out})
      } else {
        let check_in = "", check_out = "";

        if (this.check_in_early === 0) {
          check_in = this.t("check_in_early_0", {"check_in": this.check_in})
        } else if (this.check_in_early === 2) {
          check_in = this.t("check_in_early_2", {"check_in": this.check_in})
        }

        if (this.check_out_late === 0) {
          check_out = this.t("check_out_late_0", {"check_out": this.check_out})
        } else if (this.check_out_late === 2) {
          check_out = this.t("check_out_late_2", {"check_out": this.check_out})
        }

        return (check_in && check_out) ?`${check_in}<br>${check_out}` : ""
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.is-desktop {
  &.rules-booking {
    padding: 0;
    margin-top: 40px;

    .rules-booking {
      &__grid {
        display: flex;
        flex-wrap: wrap;

        .rule {
          width: 50%;
          &:nth-child(odd) {
            padding-right: 10px;
          }
          &:nth-child(even) {
            padding-left: 10px;
          }
        }
      }
      &__additional {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: 1279px) {
    &.rules-booking {

      .rules-booking {
        &__grid {
          flex-direction: column;

          .rule {
            width: 100%;
            &:nth-child(odd) {
              padding-right: 0;
            }
            &:nth-child(even) {
              padding-left: 0;
            }
          }
        }
        &__additional {
          width: 100%;
        }
      }
    }
  }
}
.rules-booking {
  padding: 20px;
  .grandtrain-wrap {
    margin-top: 10px;
  }
  &--title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &--title-custom {
    margin-bottom: 20px;
  }
  &--body {
    padding-bottom: 5px;
    font-size: 14px;
    .rule {
      margin-bottom: 15px;
      :deep(span) {
        color: #717171;
      }
    }
    .rule-head {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}
.free-cancellation {
  background: #fff6c4;
  padding: 12px 10px;
  &--title {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50px;
      margin: 0 10px 0 0;
      border: 1px solid #417505;
      color: #417505;
      line-height: 1;
      font-size: 14px;
      font-weight: normal;
      .zero {
        display: inline-block;
        vertical-align: top;
        width: 6px;
        height: 10px;
        border-radius: 3px;
        border: solid 1.2px #417505;
        margin-right: 2px;
      }
    }
  }
  &--body {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.hint-popup {
  .icon-app-question {
    font-size: 15px;
    position: relative;
    top: 2px;
  }
}
</style>
